@font-face {
  font-family: 'DungGeunMo';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/DungGeunMo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('./fonts/GillSans.woff2') format('woff2'),
      url('./fonts/GillSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rhesmanisa';
  src: url('./fonts/Rhesmanisa-Regular.woff2') format('woff2'),
      url('./fonts/Rhesmanisa-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


@font-face {
  font-family: 'NanumSquareNeo';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/NanumSquareNeo-Variable.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NanumBarunpen';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumBarunpen.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'DungGeunMo';
  margin: 0;
  padding: 0;
}

@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

@keyframes move-twinkle-back {
  from {background-position: 0 0;}
  to {background-position: -10000px 5000px;}
}

@-webkit-keyframes shake-bl {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-bl {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

@-webkit-keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

@-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
/* text popup */
@-webkit-keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

@keyframes twinkle {
  20% {
    transform: scale(0.85);
    opacity: 0.8;
  }
}




*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.outer {
  scroll-snap-type: y mandatory;
}

main {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

section {
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.main-screen {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8.5rem;
  width: 80%;
  border-radius: 10px;
  margin-top: 2rem;
  z-index: 2;
  display: grid;
  place-items: center;
}

.main-screen img {
  width: 50px;
  margin-top: 3rem;
}

.intro {
  font-size: 2rem;
  margin-bottom: 3rem;
}

.userInputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.userInput {
  display: flex;
  flex-direction: row;
  height: 2rem;
}

.textTitle {
  font-size: 1rem;
  color: cyan;
}

.text {
  font-size: 1.5rem;
}

.mouseCursor {
  width: 3px;
  height: 1.5rem;
  margin-left: 0.1rem;
  background-color: white;
  -webkit-animation: cursor-blink 1s infinite both;
  animation: cursor-blink 1s infinite both;
}

@-webkit-keyframes cursor-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes cursor-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.menus {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 10rem;
  margin-left: 2rem;
}
.start {
  font-size: 2rem;
}

.twinkling {
  z-index: 0;
  animation: move-twinkle-back 200s linear infinite;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.alienIcon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 0rem;
}

.alienIconMute {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 0rem;
  -webkit-animation: shake-bottom 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-bottom 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.alienIconJump {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 0rem;
  -webkit-animation: bounce-top 1s both;
  animation: bounce-top 1s both;
}

.note {
	-webkit-animation: shake-bl 7s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-bl 7s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.note2{
	-webkit-animation: shake-bottom 8s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-bottom 8s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.menuKey {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  cursor: pointer;
}

.menuKey img {
  margin-top: 0rem;
  animation: blink-effect 1s step-end infinite;
  -webkit-animation: blink-effect 1s step-end infinite;
}

/* start of keyboard */
:root {
  --blue: #6db3fb;
  --yellow: #FFFFF4;
  --peach: #f3938e;
  --off-white: #f5ebd4;
  --white: #ffffff;
  --green: #A2ccb6;
  --dark-blue: rgba(64, 89, 145, 1);
}

.keyboard span {
  display: block;
  margin-top: -5px;
}

.row {
  margin-top: 2px;
  overflow: hidden;
}

.keyboard {
  -webkit-animation: text-pop-up-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: text-pop-up-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.keyboard-container {
  width: 830px;
  height: 303px;
  border: 15px solid var(--off-white);
  border-radius: 10px;
  font-size: 1.5rem;
  background-color: black;
  filter: drop-shadow(0px 20px 0.5px rgb(245, 220, 203));
  transform: scale(0.6);
  margin-bottom: -6rem;
}

.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2px 7px 2px;
}

.row {
  display: flex;
  flex-direction: row;
  padding: 0px 2px 8px 2px;
  align-items: center;
}

.lastrow {
  display: flex;
  flex-direction: row;
  padding: 2px 2px 5px 2px;
  align-items: center;
}

.numbers {
  display: flex;
  flex-direction: row;
}

.key-number {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-right: 3px;
  filter: drop-shadow(0px 8px 0.5px rgb(245, 220, 203));
  cursor: pointer;
}

.key-regular {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  filter: drop-shadow(0px 8px 0.5px rgb(248, 219, 211));
  cursor: pointer;
}

.key-last {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 8px 0.5px rgb(248, 219, 211));
  cursor: pointer;
}

.key-arrow {
  width: 50px;
  height: 50px;
  background-color: var(--blue);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  filter: drop-shadow(0px 8px 0.5px rgba(64, 89, 145, 1));
  cursor: pointer;
}

.key-arrowlast {
  width: 50px;
  height: 50px;
  background-color: var(--blue);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 8px 0.5px rgba(64, 89, 145, 1));
  cursor: pointer;
}

.key-home {
  width: 70px;
  height: 50px;
  background-color: var(--blue);
  border-radius: 5px;
  margin-top: 2px;
  margin-right: 3px;
  filter: drop-shadow(0px 8px 0.5px rgba(64, 89, 145, 1));
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.key-home a{
  text-decoration: none;
  color: black;
}

.key-backspace {
  width: 70px;
  height: 50px;
  background-color: var(--peach);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 8px 0.5px rgb(211, 112, 112, 1));
  cursor: pointer;
}

.key-tab {
  width: 100px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  filter: drop-shadow(0px 8px 0.5px rgb(248, 219, 211));
  cursor: pointer;
}

.key-tab2 {
  width: 100px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 8px 0.5px rgb(248, 219, 211));
  cursor: pointer;
}

.key-caps {
  width: 120px;
  height: 50px;
  background-color: var(--peach);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  filter: drop-shadow(0px 8px 0.5px rgb(211, 112, 112, 1));
  cursor: pointer;
}

.key-ctrl {
  width: 103px;
  height: 50px;
  background-color: var(--peach);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  filter: drop-shadow(0px 8px 0.5px rgb(211, 112, 112, 1));
  cursor: pointer;
}

.key-color {
  width: 50px;
  height: 50px;
  background-color: var(--green);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  filter: drop-shadow(0px 8px 0.5px rgb(124, 143, 119));
  cursor: pointer;
}

.key-shift {
  width: 156px;
  height: 50px;
  background-color: var(--peach);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  filter: drop-shadow(0px 8px 0.5px rgb(211, 112, 112, 1));
  cursor: pointer;
}

.key-enter {
  width: 120px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 8px 0.5px rgb(248, 219, 211));
  cursor: pointer;
}

.key-space {
  width: 330px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  margin-right: 3px;
  filter: drop-shadow(0px 8px 0.5px rgb(248, 219, 211));
  cursor: pointer;
}
/* end of keyboard */

/* start of window */

.windows {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.window {
  width: 13rem;
  height: 12rem;
  border: 2px solid var(--blue);
  font-size: 2rem;

}

.window-header {
  height: 2rem;
  background-color: var(--blue);
}

.window-body {
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* end of window */

/* animations */
/* ----------------------------------------------
 * Generated by Animista on 2022-8-23 5:10:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-pop-up-top
 * ----------------------------------------
 */
 @-webkit-keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: 0 0 5px #00FFFF, 0 0 15px #00FFFF, 0 0 20px #00FFFF, 0 0 40px #00FFFF, 0 0 60px #0077ff, 0 0 10px #00FFFF, 0 0 98px #0077ff;
    color: white;
  }
}

@keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: 0 0 5px #00FFFF, 0 0 15px #00FFFF, 0 0 20px #00FFFF, 0 0 40px #00FFFF, 0 0 60px #0077ff, 0 0 10px #00FFFF, 0 0 98px #0077ff;
    color: white;
  }
}

/* end of animations */

/*typing effct */
.wrapper {
  height: 100vh;
  /*This part is important for centering*/
  display: grid;
  place-items: center;
}

.intro {
  width: 48rem;
  animation: typing 3s steps(22), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  /*border-right: 3px solid;*/
}

@keyframes typing {
  from {
    width: 0
  }
  to {
    width: 48rem
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}
/* */

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}

@-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

/* start of outer css */
.outer {
  margin: 0;
  height: 100vh;
  overflow-y: auto;
}

.outer::-webkit-scrollbar {
  display: none;
}

/* end of outer css*/

/* start of about page */
.aboutPage {
  color: white;
  z-index: 1;
  font-size: 2rem;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  position: relative;
}

.aboutPage p{
  font-size: 7rem;
  z-index: 2;
}

.aboutPageIntro {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem 0 1rem;
  width: 97vw;
}

.aboutIntroduction {
  display: flex;
  flex-direction: column;
  width: 70vw;
}

.aboutCardContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.aboutCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1rem;
  width: 60%;
}

.aboutIntroductionText {
  font-size: 1.2rem;
  word-wrap: break-word;
  text-align: justify;
  line-height: 1.8rem;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 4rem;
}
.textContent {
  margin-left: 1rem;
}

.textContent span {
  font-family: 'NanumBarunPen';
  font-weight: light;
  line-height: 2rem;
}

.missile {
  width: 6px;
  height: 35px;
  background-color: white;
  position: absolute;
  -webkit-animation: shoot-up 0.4s ease-out 1 both;
  animation: shoot-up 0.4s ease-out 1 both;
  z-index: 6;
}

@-webkit-keyframes shoot-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}

@keyframes shoot-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2023-7-3 3:40:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  80% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  90% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  80% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  90% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
}

.monster {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 5;
}

.boom {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 5;
  -webkit-animation: fade-out 0.4s ease-out 1s both;
	        animation: fade-out 0.4s ease-out 1s both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            
  }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2023-7-3 3:55:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-2
 * ----------------------------------------
 */
 @-webkit-keyframes flicker-2 {
  0% {
    opacity: 0.2;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  45.99% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  46.9% {
    opacity: 0;
  }
  46.91% {
    opacity: 1;
  }
  51.99% {
    opacity: 1;
  }
  52% {
    opacity: 0;
  }
  52.8% {
    opacity: 0;
  }
  52.81% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flicker-2 {
  0% {
    opacity: 0.2;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  45.99% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  46.9% {
    opacity: 0;
  }
  46.91% {
    opacity: 1;
  }
  51.99% {
    opacity: 1;
  }
  52% {
    opacity: 0;
  }
  52.8% {
    opacity: 0;
  }
  52.81% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.aboutCardImageContainer {
  position: relative;
}

.myPic{
  width: 20rem;
  height: 20rem;
  object-fit: cover;
  border: 2px solid white;
  border-radius: 100%;
  box-shadow: 0 0 .2rem #fff,
            0 0 .2rem #fff,
            0 0 2rem cyan,
            0 0 0.8rem cyan,
            inset 0 0 1.3rem cyan;
  padding: 0.5rem;
  -webkit-animation: flip-in-ver-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1 both;
  animation: flip-in-ver-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1 both;
}

.myPic:hover {
  -webkit-animation: flip-vertical-right 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: flip-vertical-right 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.picHit{
  width: 20rem;
  height: 20rem;
  object-fit: cover;
  border: 2px solid white;
  border-radius: 100%;
  box-shadow: 0 0 .2rem #fff,
            0 0 .2rem #fff,
            0 0 2rem cyan,
            0 0 0.8rem cyan,
            inset 0 0 1.3rem cyan;
  padding: 0.5rem;
  -webkit-animation: flicker-2 1.5s linear 1 both;
  animation: flicker-2 1.5s linear 1 both;
}

.picChange{
  -webkit-animation: flip-in-ver-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1 both;
  animation: flip-in-ver-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1 both;
  width: 20rem;
  height: 20rem;
  object-fit: cover;
  border: 2px solid white;
  border-radius: 100%;
  padding: 0.5rem;
}

.ring {
  width: 27rem;
  height: 26rem;
  border-left: 7px solid white;
  border-top: 7px solid white;
  border-radius: 50%;
  top: -3rem; 
  left: -3rem;
  padding: 0.5rem;
  position: absolute;
  transform: rotate3d(1, 2, -3, 170deg);
}


.aboutCard span {
  margin-right: 1rem;
}

.aboutCardDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.textContent {
  font-family: 'NanumBarunPen';
}

.detailCategoryContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 3.7rem;
  z-index: 4;
}

.detailCategory {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.detailCategory span {
  font-size: 1.2rem;
  font-family: 'NanumBarunPen';
  font-weight: light;
}

.categoryIcon {
  font-size: '2rem';
  color: 'cyan';
  filter: 'drop-shadow(0px 0px 8px rgb(0 255 255 / 1))';
  margin-right: 0.5rem;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-7-5 23:13:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-4
 * ----------------------------------------
 */
 @-webkit-keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0.8;
  }
  32.8% {
    opacity: 0.8;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0.8;
  }
  35.7% {
    opacity: 0.8;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0.8;
  }
  37.6% {
    opacity: 0.8;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0.8;
  }
  68.4% {
    opacity: 0.8;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0.8;
  }
  96.7% {
    opacity: 0.8;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0.8;
  }
  99.6% {
    opacity: 0.8;
  }
  99.62% {
    opacity: 1;
  }
}
@keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0.8;
  }
  32.8% {
    opacity: 0.8;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0.8;
  }
  35.7% {
    opacity: 0.8;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0.8;
  }
  37.6% {
    opacity: 0.8;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0.8;
  }
  68.4% {
    opacity: 0.8;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0.8;
  }
  96.7% {
    opacity: 0.8;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0.8;
  }
  99.6% {
    opacity: 0.8;
  }
  99.62% {
    opacity: 1;
  }
}

.cardTitle {
  font-family: 'Rhesmanisa' !important;
  font-weight: 200;
  font-size: 4rem;
  color: white;
  text-shadow: 0 0 5px #00FFFF, 0 0 15px #00FFFF, 0 0 20px #00FFFF, 0 0 40px #00FFFF, 0 0 60px #0077ff, 0 0 10px #00FFFF;
	-webkit-animation: flicker-4 10s linear 2s infinite both;
	        animation: flicker-4 10s linear 2s infinite both;
}

.cardTitleHit {
  font-weight: 200;
  font-size: 4rem;
  color: white;
  text-shadow: 0 0 5px #00FFFF, 0 0 15px #00FFFF, 0 0 20px #00FFFF, 0 0 40px #00FFFF, 0 0 60px #0077ff, 0 0 10px #00FFFF;
  -webkit-animation: flicker-2 1.5s linear 1 both;
  animation: flicker-2 1.5s linear 1 both;
  font-family: 'Rhesmanisa' !important;
}

.introductionHit {
  font-weight: 200;
  font-size: 4rem;
  color: white;
  text-shadow: 0 0 5px #00FFFF, 0 0 15px #00FFFF, 0 0 20px #00FFFF, 0 0 40px #00FFFF, 0 0 60px #0077ff, 0 0 10px #00FFFF;
  -webkit-animation: flicker-2 1.5s linear 1 both;
  animation: flicker-2 1.5s linear 1 both;
  font-family: 'Rhesmanisa' !important;
}

.skillsHit {
  font-weight: 200;
  font-size: 4rem;
  color: white;
  text-shadow: 0 0 5px #00FFFF, 0 0 15px #00FFFF, 0 0 20px #00FFFF, 0 0 40px #00FFFF, 0 0 60px #0077ff, 0 0 10px #00FFFF;
  -webkit-animation: flicker-2 1.5s linear 1 both;
  animation: flicker-2 1.5s linear 1 both;
  font-family: 'Rhesmanisa' !important;
}

.cardTitleChange {
  font-family: 'DungGeunMo' !important;
  font-size: 3rem;
  color: cyan;
  text-shadow: none;
}

.aboutPageSkills {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.skillsIcon {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  justify-content: space-between;
  width: 100%;
}

.icon {
  position: relative;
  width: 4rem;
  height: 4rem;
}

.icon span {
  opacity: 0;
  font-size: 1rem;
  position: absolute;
  top: 40%;
  left: 20%;
  text-align: center;
}

.icon img {
  width: 4rem;
  height: 4rem;
}

.icon:hover span{
  opacity: 1;
}

.icon:hover img {
  opacity: 0.1;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-7-1 2:30:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-ver-right
 * ----------------------------------------
 */
 @-webkit-keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
            transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
}
@keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
            transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
}

.help {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem 4rem 2rem;
}

.help span {
  font-size: 3rem;
  color: cyan;
}

.helpTitle {
  animation: blink-effect 2s ease-in 1.5s infinite;
  -webkit-animation: blink-effect 2s ease-in 1.5s infinite;
}

.scrollHelp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.scrollHelp span{
  font-size: 0.8rem;
  color: white;
  font-family: 'NanumBarunPen';
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

.downArrow {
	-webkit-animation: slide-bottom 2s ease-in infinite both;
	        animation: slide-bottom 2s ease-in infinite both;
  color: white;
  width: 10rem;
  font-size: 10rem;
}
/* end of about page */

/* start of project page */
.projectPage {
  color: white;
  z-index: 1;
  font-size: 2rem;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-x: scroll;
  overflow-y: scroll;
}

.projectPage p{
  font-size: 4rem;
}

.projectPageIntro {
  display: flex;
  height: 10vh;
  margin: 0 1rem 0 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.project {
  height: 85vh;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='yellow' stroke-width='5' stroke-dasharray='3%2c 16' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");

  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5rem;
  position: relative;
}

.project span{
  font-family: 'NanumBarunPen';
}

.pacBoy {
  width: 0;
  height: 0;
  border-top: 15px solid #FFF200;
  border-left: 15px solid #FFF200;
  border-bottom: 15px solid #FFF200;
  border-right: 15px solid transparent;
  border-radius: 50%;
  animation: move-x 20s linear 0.5s infinite both, mouth 0.5s steps(1, end) 0s infinite both;
  -webkit-animation: move-x 20s linear 0.5s infinite both, mouth 0.5s steps(1, end) 0s infinite both;
  z-index: 5;
  position: absolute;
  left: 0;
}

@keyframes move-x {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
  }
}

@-webkit-keyframes move-x {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}

@keyframes mouth {
  0% {
    -webkit-border-right: 15px solid #FFF200;
            border-right: 15px solid #FFF200;
  }
  50% {
    -webkit-border-right: 15px solid transparent;
            border-right: 15px solid transparent;
  }
  0% {
    -webkit-border-right: 15px solid #FFF200;
            border-right: 15px solid #FFF200;
  }
}

@-webkit-keyframes mouth {
  0% {
    -webkit-border-right: 15px solid #FFF200;
            border-right: 15px solid #FFF200;
  }
  50% {
    -webkit-border-right: 15px solid transparent;
            border-right: 15px solid transparent;
  }
  100% {
    -webkit-border-right: 15px solid #FFF200;
            border-right: 15px solid #FFF200;
  }
}


.projectImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.projectImage2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.image1 {
  position: relative;
}

.image1 img{
  height: 70vh;
  opacity: 1;
}

.image1 span {
  opacity: 0;
}

.image1Desc{
  position: absolute;
  width: 75%;
  top: 2%;
  left: 14%;
  align-self: center;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 96%;
}

.image1DescContent {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.7rem;
  text-align: justify;
  overflow-y: scroll;
}

.image1:hover img{
  opacity: 0.15;
  z-index: 5;
}

.image1:hover span {
  opacity: 1;
}

.image2 {
  position: relative;
}

.image2 img{
  width: 100%;
  opacity: 1;
}

.image2 span {
  opacity: 0;
}

.image2Desc{
  position: absolute;
  width: 68%;
  top: 3%;
  left: 17%;
  align-self: center;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 94%;
}

.image2DescContent {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.7rem;
  text-align: justify;
  overflow-y: scroll;
}

.image2:hover img{
  opacity: 0.15;
  z-index: 5;
}

.image2:hover span {
  opacity: 1;
}

.image3 {
  position: relative;
}

.image3 img{
  width: 100%;
  opacity: 1;
  border: 1px solid white;
}

.image3 span {
  opacity: 0;
}

.image3Desc{
  position: absolute;
  width: 68%;
  top: 3%;
  left: 17%;
  align-self: center;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 94%;
}

.image3DescContent {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.7rem;
  text-align: justify;
  overflow-y: scroll;
}

.image3:hover img{
  opacity: 0.15;
}

.image3:hover span {
  opacity: 1;
}

.tip {
  font-size: 0.7rem;
  margin-bottom: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.projectImageButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  color: black;
  margin-top: -0.8rem;
  width: 4.5rem;
  background-color: white;
  border-radius: 5px;
  z-index: 2;
  border: 2px solid white;
  box-shadow: 0 0 0 1px #000 inset;
}

.projectImageButtons span{
  font-family: 'NanumBarunPen';
  font-size: 1rem;
  letter-spacing: 0.3rem;
  font-weight: bold;
}

.descriptionKor {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: justify;
}

.descriptionKor span {
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.descriptionKor2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: justify;
  margin-top: 1rem;
}

.descriptionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.descriptionButtons span {
  font-family: 'DungGeunMo';
}

.projectName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}
.stacks {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0.5rem;
}
.projectTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.projectTitleDetail{
  font-size: 1.2rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'NanumBarunPen' !important;
  margin-right: 0.5rem;
}

.projectName span {
  font-size: 3rem;
  font-family: 'DungGeunMo';
}

.projectName img {
  cursor: pointer;
  margin-left: 1rem;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: twinkle;
  margin-right: 0.5rem;
}

.projectLower {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 47.5%;
  margin-left: 2rem;
}

.projectDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.description a {
  text-decoration: none;
  color: white
}

.description img {
  cursor: pointer;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: twinkle;
  margin-right: 0.5rem;
}

.projectFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  margin: 0 2rem 0.5rem 2rem;
}

.scoreInfo {
  display: flex;
  flex-direction: row;
  font-size: 2.7rem;
  color: white;
}

.livesInfo {
  display: flex;
  flex-direction: row;
  font-size: 2.7rem;
  color: white;
  align-items: center;
  width: 17rem;
}

.livesInfo img{
  width: 2.7rem;
  margin-left: 1rem;
}

.description {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  cursor: pointer;
}

.arrow {
  cursor: pointer;
  align-self: flex-start;
}
/* end of about page */

/* start of other page */
.otherPage {
  color: white;
  z-index: 1;
  font-size: 2rem;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  overflow: hidden;
}

.otherPageIntro {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem 0 1rem;
}

.otherPageIntro p{
  font-size: 5rem;
  margin: 1rem 1rem 0 1rem;
}

.otherPageLang button{
  margin-left: 1rem;
  padding: 0.3rem;
  font-size: 1rem;
  cursor: pointer;
	color: black;
}

.otherPageBody {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100vw;
}

.otherPageDesc {
	width: 45%;
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.otherPageInstruction {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.otherPageInstruction span {
  font-family: 'NanumBarunPen';
}

.instructionsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.instructionsContent {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1rem;
}

.instructionsContent span {
  font-size: 1rem;
}

.steps {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.3rem;
}

.instructionsButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 7rem;
  justify-content: center;
}

.cylinder {
  width: 4rem;
  height: 1.5rem;
  border-radius: 50px;
  border: 2px solid white;
  position: relative;
}

.innerCircle {
  position: absolute;
  left: 0px;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background-color: white;
  top: 2px;
}

.cross {
  background: white;
  height: 100px;
  position: relative;
  width: 35px;
  border-radius: 5px;
  transform: scale(0.7);
}
.cross:after {
  background: white;
  content: "";
  height: 35px;
  left: -32px;
  position: absolute;
  top: 33px;
  width: 100px;
  border-radius: 5px;
}

.buttonAB {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
  margin-left: 50px;
}

.circle1 {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: 0;
  margin-top: 1.5rem;
}

.circle2 {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: 35px;
}

.descTitle {
  height: 30%;
  width: 80%;
}

.descTitle span {
  font-family: 'NanumBarunPen';
}

.descBody {
  font-size: 1.2rem;
  line-height: 2rem;
  margin-top: 1rem;
}


.gameboyContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 55rem;
}

.gameboyHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 48rem;
  position: relative;
}

.gameboyHeadDeco {
  display: flex;
  position: absolute;
  background-color: #cbc3c9;
  width: 47.8rem;
  height: 4rem;
  left: 0.2%;
  top: 0.2rem;
  border-radius: 10px;
  z-index: 2;
}
.gameboyHeadLeft {
  height: 3rem;
  width: 3rem;
  background-color: #e6dfe3;
  border-radius: 1rem 5px 3px 3px;
  margin-bottom: 0.3rem;
  z-index: 3;
  border-bottom: 3px solid #aa9ba5;
  border-right: 3px solid #aa9ba5;
  box-shadow: 18px 18px 3px 0px rgba(245,240,240,255) inset;

}

.gameboyHeadMiddle {
  height: 3rem;
  width: 41rem;
  background-color: #e6dfe3;
  margin-bottom: 0.3rem;
  z-index: 3;
  border-left: 3px solid #f7f2f2;
  border-bottom: 3px solid #aa9ba5;
  border-right: 3px solid #aa9ba5;
  border-radius: 5px 5px 3px 3px;
  box-shadow: 0px 18px 3px 0px rgba(245,240,240,255) inset;
}

.gameboyHeadRight {
  height: 3rem;
  width: 3rem;
  background-color: #e6dfe3;
  border-radius: 5px 1rem 3px 3px;
  margin-bottom: 0.3rem;
  z-index: 3;
  border-left: 3px solid #f7f2f2;
  border-bottom: 3px solid #aa9ba5;
  box-shadow: 0px 18px 3px 0px rgba(245,240,240,255) inset, -18px 2px 4px 0px rgba(187,172,189,255) inset;

}

.gameboyHead span {
  font-family: 'Gill Sans' !important;
  font-size: 1rem;
}

.MuiSwitch-thumb {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
  animation: pulsate-fwd 0.5s ease-in-out infinite both;
  
}

.gameboyDarkLine {
  background-color: #e0d9dd;
  height: 1rem;
  width: 50rem;
  border-top: 5px solid #b1a0a6;
  border-radius: 5px 5px 0 0;
  border-bottom: 5px solid #f9f2f5;
}

.gameboySwitch {
  margin-top: 0.2rem;
}

.gameboySwitch span {
  color: #817394;
  font-family: 'Gill Sans';
  font-weight: bold;
}

.gameboy {
  background-color: #e6dfe3;
  width: 48rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  border-top: 3px solid #ebe4e8;
  box-shadow: 18px 1px 3px 0px rgba(245,240,240,255) inset, -18px -3px 7px 0px rgba(187,172,189,255) inset;
}

.gameboySwitch {
  font-family: 'Gill Sans';
}

.gameboyScreen {
  width: 41rem;
  height: 27rem;
  border-radius: 15px 15px 100px 15px;
  box-shadow: 5px 5px 2px 0px #817294 inset, -2px -2px 2px 0px #817294 inset;
  background-color: #9289a2;
  display: flex;
  flex-direction: column;
  border: 2px solid #a99bb4;
  margin-top: 2rem;
}

.gameboyTopDeco {
  display: flex;
  flex-direction: row;
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
}

.gameboyTopDeco span {
  font-size: 0.8rem;
  font-family: 'Gill Sans';
}

.gameboyBottomDeco {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
}

.gameboyLight span {
  font-size: 0.7rem;
  font-family: 'Gill Sans';
}

.gameboyLight {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 3rem 5rem 3rem;
}

.redLighting {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #db0e0e;
  border: 2px solid #8d1014;
  box-shadow: 2px 0px 5px 1px #ff835c inset;
  outline: 2px solid #b8a3b6;
  transform: rotate(0.5turn);
  margin-bottom: 1rem;
}

.redLightingOff {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2e2828;
  border: 2px solid #181515;
  box-shadow: 2px 0px 5px 1px #5f5a59 inset;
  outline: 2px solid #8b7d8a;
  transform: rotate(0.5turn);
  margin-bottom: 1rem;
}


.gameboyGreen {
  background-color: #a6b327;
  width: 23rem;
  height: 23rem;
  border-radius: 5px;
  border: 3px solid #a49ca6;
  box-shadow: 3px 3px 10px 2px #323412 inset, -1px -1px 6px 2px #5a5e25 inset;
}

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loading span {
  color: #0E3D0C;
  font-size: 3rem;
}

.screenImages {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow-y: hidden;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 3px 3px 10px 2px #323412 inset, -1px -1px 6px 2px #5a5e25 inset;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.screenImages img {
  width: 98%;
  height: 100%;
  object-fit: contain;
}

.gameboyLine1 {
  background: #943874;
  width: 6.5rem;
  height: 3px;
  margin: 0 2rem 0.3rem 0.5rem;
}

.gameboyLine2 {
  background: #351660;
  width: 6.5rem;
  height: 3px;
  margin: 0 2rem 0.3rem 0.5rem;
}

.gameboyButtons {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 4rem 5rem 0rem 5.5rem;
}

.buttonShadow {
  width: 11rem;
  height: 11rem;
  border-radius: 50%;
  background-color: #cbc1c7;
  position: absolute;
}

.buttonMove {
  background: #090a13;
  height: 11.5rem;
  position: relative;
  width: 4rem;
  outline: 5px solid #070811;
  border-radius: 5px;
  margin-left: 7.8rem;
  border: 2px solid rgba(125,117,128,0.75);
}

.buttonMove::after{
  background: #090a13;
  content: "";
  height: 4rem;
  left: -62px;
  position: absolute;
  top: 58px;
  width: 11.5rem;
  outline: 5px solid #070811;
  border-radius: 5px;
  border: 2px solid rgba(125,117,128,0.75);
}

.buttonMoveBox {
  width: 4.3rem;
  height: 4.9rem;
  background: #090a13;
  position: relative;
  transform: translate(179%, -168%);
}

.buttonMoveCircle {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #070811;
  position: relative;
  transform: translate(16%, -340%) rotate(-0.9turn);
  border: 3px solid #54526c;
  box-shadow: 3px 0px 2px 0px #474754 inset, 2px 0px 2px 1px #c0bdc5;
}

.miniButtons {
  display: flex;
  flex-direction: row;
  margin-right: 5rem;
}

.buttonA {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #840c5f;
  border: 3px solid #5a1c3b;
  box-shadow: 3px 0px 2px 0px #f29cc9 inset, 3px 0px 3px 2px #250816;
  transform: rotate(0.1turn);
  margin-top: 4rem;
  margin-right: 2rem;
}

.buttonB {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #840c5f;
  border: 3px solid #5a1c3b;
  box-shadow: 3px 0px 2px 0px #f29cc9 inset, 3px 0px 3px 2px #250816;
  transform: rotate(0.1turn);
}

.gameboyTitle {
  width: 100%;
  display: flex;
  margin-left: 7rem;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 0.7rem;
  font-family: 'Gill Sans';
}

.title1 {
  font-family: 'Gill Sans';
  color: #443796;
  font-weight: 600;
  margin-bottom: 0.6rem;
}

.title2 {
  font-size: 3rem;
  margin-left: 0.5rem;
  font-style: italic;
  font-family: 'Gill Sans';
  font-weight: bold;
  color: #443796;
}

/* end of other page */

/* start of contact page */
.contactPage {
  color: white;
  z-index: 1;
  font-size: 2rem;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;
}

.contactPageIntro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.contactTitle {
  font-weight: bold;
  --text: #FFFF00;
  --shadow: #FF0000;
  -webkit-animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  font-size: 6.5rem;
  color: #FFFF00;
  text-shadow:
    0px 12px      var(--shadow),
    0px 11.75px  var(--shadow),
    0px 11.5px    var(--shadow),
    0px 11.25px  var(--shadow),
    0px 11px      var(--shadow),
    0px 10.75px  var(--shadow),
    0px 10.5px    var(--shadow),
    0px 10.25px  var(--shadow),
    0px 10px      var(--shadow),
    0px 9.75px    var(--shadow),
    0px 9.5px      var(--shadow),
    0px 9.25px    var(--shadow),
    0px 9px        var(--shadow),
    0px 8.75px    var(--shadow),
    0px 8.5px      var(--shadow),
    0px 8.25px    var(--shadow),
    0px 8px        var(--shadow),
    0px 7.75px    var(--shadow),
    0px 7.5px      var(--shadow),
    0px 7.25px    var(--shadow),
    0px 7px        var(--shadow),
    0px 6.75px    var(--shadow),
    0px 6.5px      var(--shadow),
    0px 6.25px    var(--shadow),
    0px 6px        var(--shadow), 
    0px 5.75px  var(--shadow), 
    0px 5.5px    var(--shadow), 
    0px 5.25px  var(--shadow),
    0px 5px        var(--shadow), 
    0px 4.75px  var(--shadow), 
    0px 4.5px    var(--shadow), 
    0px 4.25px  var(--shadow),
    0px 4px        var(--shadow),
    0px 3.75px  var(--shadow),
    0px 3.5px    var(--shadow),
    0px 3.25px  var(--shadow),
    0px 3px        var(--shadow),
    0px 2.75px  var(--shadow),
    0px 2.5px    var(--shadow),
    0px 2.25px  var(--shadow),
    0px 2px        var(--shadow),
    0px 1.75px  var(--shadow),
    0px 1.5px    var(--shadow),
    0px 1.25px  var(--shadow),
    0px 1px        var(--shadow),
    0px 0.75px  var(--shadow),
    0px 0.5px    var(--shadow),
    0px 0.25px  var(--shadow);
    margin-bottom: 1rem;
    margin-top: 2rem;
}

@-webkit-keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.contactDesc {
  font-size: 2rem;
  color: cyan;
  margin-bottom: 4rem;
  -webkit-animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.scoreboard {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  justify-content: flex-start;
  font-size: 2.3rem;
  align-items: center;
}

.bestPlayer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 30%;
  font-size: 2.5rem;
}

.players {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50%;
}

.playerSpec {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.boardTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.33%;
}

@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.cursor {
  font-weight: lighter;
  font-size: 2rem;
  -webkit-animation: blink-1 2s infinite both;
    animation: blink-1 2s infinite both;
}

.boardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.boardContent span {
  margin-bottom: 1rem;
}

.boardContent img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.currentName {
  display: flex;
  flex-direction: row;

}

.currentName span {
  margin-right: 0.4rem;
}

.scoreboardName {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;
}

.nameLine1 {
  cursor: pointer;
}

.scoreboardName span {
  margin-right: 1rem;
}

.continue {
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  color: cyan;
}

.coin {
  margin-top: 1rem;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: #FFFF00;;
  cursor: pointer;
  -webkit-animation: jello-horizontal 1.8s infinite both;
	        animation: jello-horizontal 1.8s infinite both;
}


/* end of contact page */

/* coin animation from jh3y */
/**
  * Spinning coin fall
  *
  * @author jh3y
*/
@-webkit-keyframes spinning-coin-fall {
  0% {
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(0deg) rotateX(0deg);
            transform: translate(-50%, -50%) perspective(160px) rotateY(0deg) rotateX(0deg); }
  10% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(-65deg) rotateX(0deg);
            transform: translate(-50%, -50%) perspective(160px) rotateY(-65deg) rotateX(0deg); }
  70% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(1440deg) rotateX(0deg);
            transform: translate(-50%, -50%) perspective(160px) rotateY(1440deg) rotateX(0deg); }
  90%,
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(2880deg) rotateX(90deg);
            transform: translate(-50%, -50%) perspective(160px) rotateY(2880deg) rotateX(90deg); } }
@keyframes spinning-coin-fall {
  0% {
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(0deg) rotateX(0deg);
            transform: translate(-50%, -50%) perspective(160px) rotateY(0deg) rotateX(0deg); }
  10% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(-65deg) rotateX(0deg);
            transform: translate(-50%, -50%) perspective(160px) rotateY(-65deg) rotateX(0deg); }
  70% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(1440deg) rotateX(0deg);
            transform: translate(-50%, -50%) perspective(160px) rotateY(1440deg) rotateX(0deg); }
  90%,
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(2880deg) rotateX(90deg);
            transform: translate(-50%, -50%) perspective(160px) rotateY(2880deg) rotateX(90deg); } }

.spinning-coin-fall:before {
  -webkit-animation: spinning-coin-fall 3s ;
          animation: spinning-coin-fall 3s ;
  background-color: #FFFF00;
  border-radius: 100%;
  content: '';
  height: 30px;
  position: absolute;
  top: 88%;
  left: 50%;
  opacity: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  width: 30px; 
}

/* ----------------------------------------------
 * Generated by Animista on 2023-6-22 5:3:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

